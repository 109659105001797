import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <div className="container-xs">
              <Image
                src={require("./../../assets/images/aaronlionheart_circle.png")}
                alt="Aaron Lionheart"
                width={256}
                height={256}
                className="reveal-from-bottom"
                data-reveal-delay="300"
                style={{ padding: "20px", margin: "10px auto" }}
              />
              <h1
                className="mt-0 mb-16 reveal-from-bottom"
                data-reveal-delay="200"
              >
                <span className="text-color-primary">Aaron Lionheart</span>
              </h1>
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Aaron Lionheart is a multi-talented and versatile individual who
                has made a name for himself in the world of video game
                development. With a background in software engineering and a
                passion for the arts.
              </p>
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                With his unique blend of technical expertise and artistic
                vision, Aaron Lionheart continues to push the boundaries of what is possible in the industry, 
                and his work continues to inspire and captivate audiences, and consumers around the
                world.
              </p>
              <br/>
              <div className="reveal-from-bottom" data-reveal-delay="400">
                <ButtonGroup>
                  <Link to="./business">
                    <Button color="primary" wideMobile>
                      Business Contact
                    </Button>
                  </Link>
                  <Link to="./resume">
                    <Button color="dark" wideMobile>
                      Resume / Experience
                    </Button>
                  </Link>
                </ButtonGroup>
              </div>
              <br/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
