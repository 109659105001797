import React from 'react';
// import sections
import Testimonial from '../components/sections/Testimonial';
import GenericSection from '../components/sections/GenericSection';

const Resume = () => {

  return (
    <>
      <Testimonial />
      <GenericSection>
<table>
	<thead>
	<tr>
		<th>Skill Name</th>
		<th>Experience</th>
	</tr>
	</thead>
	<tbody>
	<tr>
		<td>.Net Framework/Core Using C# / F#</td>
		<td>16 Years</td>
	</tr>
	<tr>
		<td>C / C++</td>
		<td>16 Years</td>
	</tr>
	<tr>
		<td>Unity Engine</td>
		<td>7 Years</td>
	</tr>
	<tr>
		<td>SQL</td>
		<td>9 Years</td>
	</tr>
	<tr>
		<td>Lua / Python</td>
		<td>9 Years</td>
	</tr>
	<tr>
		<td>Xamarin App Development (iOS, Android, Linux, Windows)</td>
		<td>4 Years</td>
	</tr>
	<tr>
		<td>Forms / XAML / WPF</td>
		<td>9 Years</td>
	</tr>
	<tr>
		<td>HTML / CSS / JavaScript / Typescript/ React / Angular</td>
		<td>16 Years</td>
	</tr>
	<tr>
		<td>PHP</td>
		<td>7 Years</td>
	</tr>
	<tr>
		<td>Azure</td>
		<td>6 Years</td>
	</tr>
	<tr>
		<td>AWS</td>
		<td>6 Years</td>
	</tr>
	<tr>
		<td>Asp.Net / Entity Framework</td>
		<td>7 Years</td>
	</tr>
	<tr>
		<td>Git / GitHub / GitLab</td>
		<td>10 Years</td>
	</tr>
	<tr>
		<td>Unit Testing JavaScript & C#</td>
		<td>5 Years</td>
	</tr>
	<tr>
		<td>Cypress Browser Automation Testing</td>
		<td>4 Years</td>
	</tr>
  <tr>
		<td>Hardware Setup / Network Setup / Computer Building</td>
		<td>14 Years</td>
	</tr>
	<tr>
		<td>Realtime Networking for Multiplayer Games</td>
		<td>4 Years</td>
	</tr>
	<tr>
		<td>Vector / Matrix Math (3D Positional / Rotational Math</td>
		<td>6 Years</td>
	</tr>
	<tr>
		<td>Restful API Development</td>
		<td>7 Years</td>
	</tr>
	<tr>
		<td>CI/CD TeamCity Esq (Build Pipeline Management & Continuous Delivery)</td>
		<td>5 Years</td>
	</tr>
	</tbody>
</table>
      </GenericSection>
    </>
  );
}

export default Resume;